import React from 'react';
import { Helmet } from 'react-helmet'

import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';

import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import PagePreloader from "../../helpers/PagePreloader";
import TicketsGrid from "./TicketsGrid";
import { withTranslation, Trans } from 'react-i18next';


class Tickets extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

  }

  componentDidMount() {

    this.setState({ loading: false });

  }


  render() {
    return (
      <React.Fragment>

        <Helmet>
          <title>{this.props.pageTitle}</title>
        </Helmet>

        <section id="tickets" className="inner-wrapper">
          <TriangleAnimated right />
          <TriangleAnimated left />
          <TriangleAnimated right bottom />
          <TriangleAnimated left bottom />



          <h1>{this.props.t('Tickets')}</h1>

          <div className="body container">
            {this.props.t('Tickets für das alba Festival 2025 sind streng limitiert und exklusiv bei ticketmaster.ch erhältlich.')}
          </div>


          <TicketsGrid  />



          <div className="alert-bar container">
            <h3>{this.props.t('Vorsicht beim Ticketverkauf')}</h3>
            <div className="body">

              {this.props.t('Das alba Festival empfiehlt, nur bei den offiziellen, vom Veranstalter autorisierten Vorverkaufsstellen Tickets zu kaufen. Wir raten dringend davon ab, Tickets zu kaufen, die vor dem offiziellen Vorverkaufsstart angeboten werden. Die Tickethändler sind zu diesem Zeitpunkt nicht im Besitz gültiger Tickets, und du riskierst, dass du die bezahlten Tickets nie erhältst. Folgende Plattformen sind in der Regel keine offiziellen Vorverkaufsstellen, sondern Wiederverkäufer oder Tauschbörsen wie Alltickets, Viagogo, Onlineticketshop, Worldticketshop, Vienna Ticketoffice, Ticketbande, Ebay, Ricardo. Diese Plattformen werden dazu genutzt, spekulativen Handel mit dem An- und Verkauf von Veranstaltungstickets zu betreiben und sollten deshalb gemieden werden.')}

            </div>
          </div>


          <Ticket />
        </section>

        <PagePreloader page={this} />

      </React.Fragment>
    );
  }
}


export default withTranslation()(Tickets);
