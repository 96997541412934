import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Link } from "react-router-dom";

import FooterMap from './FooterMap'
import FooterPartner from './FooterPartner'
import FooterNewsletter from './FooterNewsletter'

import TimeEventLogo from '../../assets/images/time-event-white.svg'
import SmpaLogo from '../../assets/images/smpa_logo_rund_sw.png'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons'

import Triangle from "../../blocks/triangle/Triangle";
import TrianglePath from "../../blocks/triangle/TrianglePath";
import TrianglePathAnimated from "../../blocks/triangle/TrianglePathAnimated";


import Cookies from 'js-cookie'
import { withTranslation, Trans } from 'react-i18next';


import FooterLeftPolygon from "../../assets/images/footerLeftPolygon.svg";
import FooterRightPolygon from "../../assets/images/footerRightPolygon.svg";

//import SpotifyWebPlayer from 'react-spotify-web-playback';
//import { SpotifyAuth, Scopes } from 'react-spotify-auth'
//import 'react-spotify-auth/dist/index.css'
//import { SpotifyApiContext } from 'react-spotify-api'
//import SpotifyPlayer from 'react-spotify-player';
//import Player from '../../modules/player/Player';
//import ReactPlayer from 'react-player'


class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      token: Cookies.get('spotifyAuthToken'),
    }
  }

  componentDidMount() {
    var component = this;

    var tokenInterval = setInterval(function () {
      var token = Cookies.get('spotifyAuthToken');

      if (token) {
        component.setState({ token: token });
        clearInterval(tokenInterval);
      }

    }, 3000);
  }

  render() {

    const size = {
      width: '100%',
      height: '100%',
    };
    const view = 'list'; // or 'coverart'
    const theme = 'black'; // or 'white'


    return (
      <>

        <div class="footer">

          <footer id="footer-map-bar">


          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.8227933610474!2d8.52887417645263!3d47.376377003847175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a054b8cef25%3A0xb9353b498630c24!2sKasernenareal!5e0!3m2!1sde!2sae!4v1737958758896!5m2!1sde!2sae" width="100%" style={{filter: 'grayscale(1)'}} height="370" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

           {/** <FooterMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC8Ah1DQsSuF0KHB8hYvMi3yrnVstIl7DU"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            /> */}


            <address onClick={() => { window.open('https://www.google.com/maps/dir//Kasernenareal,+Milit%C3%A4rstrasse,+8004+Z%C3%BCrich/@47.3754455,8.5339361,14z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47900a054b8cef25:0xb9353b498630c24!2m2!1d8.5314491!2d47.3763734!3e3?entry=ttu', '_blank'); }}>
              <FontAwesomeIcon icon={AllLightIcons.faMap} /> &nbsp; Kasernenareal, Militärstrasse, 8004 Zürich
            </address>

          </footer>

         <footer id="footer-partner-bar">
            <h5>{this.props.t('Sponsoren & Partner')}</h5>
            <FooterPartner />
          </footer> 




          <footer id="footer-info-bar">
            <h6>{this.props.t('Follow Us')}</h6>
            <ul
              style={{
                height: "100%",
                textAlign: 'center',
                fontSize: "1vw",
                padding: 0,
                paddingBottom: 20,
                margin: 0,
                listStyle: "none",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center"
              }}
              className="social-bar"
            >
              <li>
                <a href="https://www.facebook.com/albafestival/" target="_blank">
                  <FontAwesomeIcon icon={AllBrandIcons.faFacebook} />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/albafestival/?hl=de" target="_blank">
                  <FontAwesomeIcon icon={AllBrandIcons.faInstagram} />
                </a>
              </li>
              {/*<li>
              <a href="https://twitter.com/albafestival?lang=de" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faTwitter} />
              </a>
            </li>*/}
              <li>
                <a href="https://www.youtube.com/channel/UCg1zQ3NNVHD_86mwN47hpdQ" target="_blank">
                  <FontAwesomeIcon icon={AllBrandIcons.faYoutube} />
                </a>
              </li>

              <li>
                <a href="https://www.tiktok.com/@albafestival" target="_blank">
                  <FontAwesomeIcon icon={AllBrandIcons.faTiktok} />
                </a>
              </li>
              {/*<li>
              <a href="https://www.snapchat.com/add/albafestival" target="_blank">
                <FontAwesomeIcon icon={AllBrandIcons.faSnapchat} />
              </a>
            </li>*/}
            </ul>

            <hr />

            <FooterNewsletter />

            <hr />


            <footer id="footer-time-event">
              <img src={TimeEventLogo} className="img-fluid" />
              <br />
              <a href="https://www.smpa.ch/" target="_blank">
                <img style={{ maxWidth: '130px', padding: '15px' }} src={SmpaLogo} className="img-fluid" />
              </a>
            </footer>


            <div className="term-bar">
              <ul
                style={{
                  height: "100%",
                  textAlign: 'center',
                  fontSize: "2vw",
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <li>
                  <a href={'https://2025.alba-festival.ch/privacy'} target="_blank">
                    {this.props.t('Datenschutz')}
                  </a>
                </li>
                <li>
                  <a href={'https://2025.alba-festival.ch/imprint'} target="_blank">
                    {this.props.t('Impressum')}
                  </a>
                </li>
                <li>
                  <a href={'https://2025.alba-festival.ch/terms'} target="_blank">
                    {this.props.t('AGB')}
                  </a>
                </li>
              </ul>
            </div>






            <div className="copyright">Copyright 2025 © alba Festival</div>

            <div className="animatesTriangleLeft">
              <img src={FooterLeftPolygon} />
            </div>

            <div className="animatesTriangleRight">
              <img src={FooterRightPolygon} />

            </div>
          </footer>





        </div>



        {/*<button type="button" class="btn btn-primary"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasPlayer" id="playerModalBtn" aria-controls="offcanvasPlayer">
        <FontAwesomeIcon icon={AllBrandIcons.faSpotify} />
      </button>






      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasPlayer" aria-labelledby="offcanvasPlayerLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasPlayerLabel">Player</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">


          <Player />
          <ReactPlayer
            playsinline={true}
            width={'100%'}
            height={'100%'}
            url='https://www.youtube.com/watch?v=PZqRgT85E0Q&list=PLQnOySyhXwaQOhNY5zaI5NV7mpROuDN0I'
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  width: '100%',
                  height: '100%',
                  playsinline: 1,
                  rel: 0
                }
              }
            }}
          />}



        </div>
      </div>*/}





      </>
    );
  }
}


export default withTranslation()(Footer);
