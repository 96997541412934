let backendBase = 'https://api.alba-festival.ch/';

let hostname = window.location.hostname;
if (hostname.includes('dev') || hostname.includes('localhost')) {
  backendBase = 'https://api.alba-festival.dev/';
}

export default  {
  backendBase: backendBase,
};
