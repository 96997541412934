import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { Lines, Planets, Cube, Ripple } from 'react-preloaders2';
import Ticket from "../../blocks/ticket/Ticket";
import './assets/css/index.css';
import TriangleAnimated from "../../blocks/triangle/TriangleAnimated";
import PagePreloader from "../../helpers/PagePreloader";
import { withTranslation } from 'react-i18next';

import Festival2019 from "./assets/images/alba-festival-history-2019.jpg";
import Festival2021 from "./assets/images/alba-festival-history-2021.jpg";
import Festival2022 from "./assets/images/alba-festival-history-2022.jpg";
import Festival2024 from "./assets/images/alba-history-2024.jpg";

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { t, pageTitle } = this.props;
    // Mapping of festival years to external YouTube aftermovie links
    const aftermovieLinks = {
      "2019": "https://www.youtube.com/watch?v=IiMgKXfU4tA",
      "2021": "#",
      "2022": "https://www.youtube.com/watch?v=nQiImAM3q8k",
      "2024": "https://www.youtube.com/watch?v=9wlD4fJWAC4"
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <section id="history" className="inner-wrapper">
          <h1>{t('History')}</h1>
          <TriangleAnimated right />
          <TriangleAnimated left />
          <TriangleAnimated right bottom />
          <TriangleAnimated left bottom />

          <div className="container">
            <div className="body">
              {t('Entdecke die besten Impressionen des vergangenen Festivals.')}
            </div>
          </div>

          <div className="container mb-5">
            <div className="row">
              {/* Festival 2019 */}
              <div className="col-sm-6 col-md-3">
                <div className="festival-item">
                  <Link style={{ textDecoration: 'none' }} to="#" type="button">
                    <img src={Festival2019} className="img-fluid" alt="Festival 2019" />
                    <h3>2019</h3>
                  </Link>
                  <div className="d-grid gap-2 mt-2">
                    <a 
                      href={aftermovieLinks["2019"]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                    >
                      Aftermovie
                    </a>
                   
                  </div>
                </div>
              </div>

              {/* Festival 2021 */}
              <div className="col-sm-6 col-md-3">
                <div className="festival-item">
                  <a 
                    style={{ textDecoration: 'none' }} 
                    href="https://2021.alba-festival.ch" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    type="button"
                  >
                    <img src={Festival2021} className="img-fluid" alt="Festival 2021" />
                    <h3>2021</h3>
                  </a>
                  <div className="d-grid gap-2 mt-2">
                    
                  </div>
                </div>
              </div>

              {/* Festival 2022 */}
              <div className="col-sm-6 col-md-3">
                <div className="festival-item">
                  <a 
                    style={{ textDecoration: 'none' }} 
                    href="https://2022.alba-festival.ch" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    type="button"
                  >
                    <img src={Festival2022} className="img-fluid" alt="Festival 2022" />
                    <h3>2022</h3>
                  </a>
                  <div className="d-grid gap-2 mt-2">
                    <a 
                      href={aftermovieLinks["2022"]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                    >
                      Aftermovie
                    </a>
                    <a 
                      href="https://2022.alba-festival.ch/gallery" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="btn btn-secondary"
                    >
                      Gallery
                    </a>
                  </div>
                </div>
              </div>

              {/* Festival 2024 */}
              <div className="col-sm-6 col-md-3">
                <div className="festival-item">
                  <a 
                    style={{ textDecoration: 'none' }} 
                    href="https://2024.alba-festival.ch" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    type="button"
                  >
                    <img src={Festival2024} className="img-fluid" alt="Festival 2024" />
                    <h3>2024</h3>
                  </a>
                  <div className="d-grid gap-2 mt-2">
                    <a 
                      href={aftermovieLinks["2024"]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                    >
                      Aftermovie
                    </a>
                    <a 
                      href="https://2024.alba-festival.ch/gallery" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="btn btn-secondary"
                    >
                      Gallery
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Ticket />
        </section>

        <PagePreloader page={this} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(History);
